import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { handleWeeklyTxns } from "utils/helpers";

const TinyBarChart = () => {
  const [response, setResponse] = useState<any>();

  useEffect(() => {
    async function fetchData() {
      try {
        const totalTinyBarChart: any = await handleWeeklyTxns();
        setResponse(
          totalTinyBarChart.filter(
            (item: any) =>
              item.feePayer_name === "Chingari" ||
              item.feePayer_name === "Kana Spot" ||
              item.feePayer_name === "Wapal" ||
              item.feePayer_name === "Floc" ||
              item.feePayer_name === "Eragon" ||
              item.feePayer_name === "Slime" ||
              item.feePayer_name === "Evolv" || 
              item.feePayer_name === "SantaBrowser" 

          )
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  const weekDataMap: Map<string, { [key: string]: number }> = new Map();

  response?.forEach((item: any) => {
    const weekStart = new Date(item.from_date);
    const weekEnd = new Date(item.to_date);
    const weekKey = formatDate(weekStart) + " - " + formatDate(weekEnd);
    if (!weekDataMap.has(weekKey)) {
      weekDataMap.set(weekKey, {
        "Kana Spot": 0,
        Chingari: 0,
        Wapal: 0,
        floc: 0,
        Eragon: 0,
        Slime: 0,
        Evolv: 0,
        SantaBrowser: 0
      });
    }
    const weekData = weekDataMap.get(weekKey)!;
    weekData[item.feePayer_name] =
      (weekData[item.feePayer_name] || 0) + item.totaltxns;
  });

  const labels: string[] = [];
  const chingariTxns: number[] = [];
  const kanaSpotTxns: number[] = [];
  const wapalSpotTxns: number[] = [];
  const flocSpotTxns: number[] = [];
  const eragonSpotTxns: number[] = [];
  const SlimeSpotTxns: number[] = [];
  const EvolvSpotTxns: number[] = [];
  const SantaBrowserSpotTxns: number[] = [];

  weekDataMap.forEach((value, key) => {
    labels.push(key);
    chingariTxns.push(value["Chingari"] || 0);
    kanaSpotTxns.push(value["Kana Spot"] || 0);
    wapalSpotTxns.push(value["Wapal"] || 0);
    flocSpotTxns.push(value["Floc"] || 0);
    eragonSpotTxns.push(value["Eragon"] || 0);
    SlimeSpotTxns.push(value["Slime"] || 0);
    EvolvSpotTxns.push(value["Evolv"] || 0);
    SantaBrowserSpotTxns.push(value["SantaBrowser"] || 0);
  });

  const data = {
    labels,
    datasets: [
      {
        label: "CHINGARI",
        data: chingariTxns,
        backgroundColor: "#00F9A9",
        barThickness: 20,
      },
      {
        label: "WAPAL",
        data: wapalSpotTxns,
        backgroundColor: "#c66b27",
        barThickness: 20,
      },
      {
        label: "KANASPOT",
        data: kanaSpotTxns,
        backgroundColor: "#FDB022",
        barThickness: 20,
      },
      {
        label: "FLOC",
        data: flocSpotTxns,
        backgroundColor: "#efe3e3",
        barThickness: 20,
      },
      {
        label: "ERAGON",
        data: eragonSpotTxns,
        backgroundColor: "#431a69",
        barThickness: 20,
      },
      {
        label: "SLIME",
        data: SlimeSpotTxns,
        backgroundColor: "#F79009",
        barThickness: 20,
      },
      {
        label: "EVOLV",
        data: EvolvSpotTxns,
        backgroundColor: "#F04438",
        barThickness: 20,
      },
      {
        label: "SANTABROWSER",
        data: SantaBrowserSpotTxns,
        backgroundColor: "#7AA0E0",
        barThickness: 20,
      },
    ],
  };

  return (
    <div className="tiny-bar-chart-container w-full">
      <Bar data={data} className="tiny-bar-chart" />
    </div>
  );
};

export default TinyBarChart;

function formatDate(date: Date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}
