/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useStore } from "store";
import { useNavigate } from "react-router-dom";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import Kanaloader from "../assets/kanaloader.json";
import {
  handleDappInformation,
  handleParticularUserData,
  handleTotalTxnsGasUsedData,
} from "utils/helpers";
import {
  AssignSignerAddress,
  chingari,
  Eragon,
  Floc,
  Kanaspot,
  Wapal,Slime,Evolv,
  SantaBrowser
} from "utils/constants";
import Lottie from "react-lottie-player";
import Sidebar from "./Sidebar";
import Header from "./Header";
const AllDApps = () => {
  const {
    updateSponseredUsers,
    setParticularFeepayerAddressTxns,
    updateParticularDappData,
    updateParticularFeepayeraddress,
  } = useStore();
  const { connected } = useWallet();
  const [chingariOnchain, setChingariOnchain] = useState<any>();
  const [kanaSpotOnchain, setKanaSpotOnchain] = useState<any>();
  const [wapalOnchain, setWapalOnchain] = useState<any>();
  const [eragonOnchain, setEragonOnchain] = useState<any>();
  const [slimeOnChain, setSlimeOnChain]=useState<any>();
  const [evolvOnChain,setEvolvOnChain]=useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [flocOnchain, setFlocOnchain] = useState<any>();
  const [santaBrowserOnchain, setSantaBrowserOnchain] = useState<any>();

  const navigate = useNavigate();

  let response: any;
  const TOtalTopFunctions = async () => {
    if (connected) {
      response = await handleTotalTxnsGasUsedData();
      const onchain = await handleParticularUserData();
      updateSponseredUsers(response.overall_users_till_now);
      setChingariOnchain(onchain[0].total_transactions_till_now);
      setKanaSpotOnchain(onchain[2].total_transactions_till_now);
      setWapalOnchain(onchain[1].total_transactions_till_now);
      setFlocOnchain(onchain[5].total_transactions_till_now);
      setEragonOnchain(onchain[4].total_transactions_till_now);
      setSlimeOnChain(onchain[6].total_transactions_till_now);
      setEvolvOnChain(onchain[7].total_transactions_till_now);
      setSantaBrowserOnchain(onchain[8]?.total_transactions_till_now || 0);
      setIsLoading(false);
    }
  };
  const handleOnchainDetails = async (address: any) => {
    const topFunctionforParticularFeepayer = await handleDappInformation(
      address
    );
    if (address === chingari) {
      setParticularFeepayerAddressTxns(AssignSignerAddress.Chingari);
    } else if (address === Kanaspot) {
      setParticularFeepayerAddressTxns(AssignSignerAddress.KanaSpot);
    } else if (address === Wapal){
      setParticularFeepayerAddressTxns(AssignSignerAddress.Wapal);
    } else if (address === Eragon){
      setParticularFeepayerAddressTxns(AssignSignerAddress.Eragon);
    } else if (address === Slime){
      setParticularFeepayerAddressTxns(AssignSignerAddress.Slime);
    } else if (address === Evolv) {
      setParticularFeepayerAddressTxns(AssignSignerAddress.Evolv);
    } else if (address === SantaBrowser) {
      setParticularFeepayerAddressTxns(AssignSignerAddress.SantaBrowser);
    } else {
      setParticularFeepayerAddressTxns(AssignSignerAddress.Floc);
    }
    updateParticularDappData(topFunctionforParticularFeepayer);
    updateParticularFeepayeraddress(address);
    navigate("/dapp-Information");
    setIsLoading(false);
  };
  useEffect(() => {
    if (connected) {
      TOtalTopFunctions();
    }
  }, [connected]);

  return (
    <div
      className={` !overflow-x-hidden  bg-[#0C0C0D]  min-h-screen h-full font-inter flex flex-row justify-start items-start w-screen xxl:!gap-[1rem] bxl:!gap-[1rem] xl:!gap-[1rem] sxl:!gap-[1rem] `}
    >
      <div className=" xxl:inline bxl:inline xl:inline sxl:inline lg:hidden md:hidden sm:hidden xd:hidden w-[5%]  ">
        <Sidebar />
      </div>
      <div className="w-full flex flex-row justify-center items-center ">
        <div className="flex flex-col  xxl:!w-[1600px] bxl:!w-[1600px] xl:!w-[90%] sxl:!w-[95.5%] lg:!w-[96%] md:!w-[100%] sm:!w-[100%] xd:!w-[100%]    ">
          <Header />
          {!connected ? (
            <>{navigate("/")}</>
          ) : (
            <>
              <div>
                <div className=" xxl:w-[75%] xl:w-[75%] sxl:w-[75%] bxl:w-[75%] lg:w-full md:w-full sm:w-full xd:w-full xxl:bg-[#17181A] xl:bg-[#17181A] sxl:bg-[#17181A] bxl:bg-[#17181A] lg:bg-[#17181A] md:bg-transparent sm:bg-transparent xd:bg-transparent border-2 md:border-none sm:border-none xd:bordern border-[#FFFFFF1A] rounded-[1rem] h-[37.125rem] overflow-auto no-scrollbar xxl:mt-28 xl:mt-28 bxl:mt-28 sxl:mt-28 lg:mt-[6rem] md:mt-[6rem] sm:mt-[6rem] xd:mt-[6rem]">
                  <div className="h-[3.5rem] border-b-2 border-[#ffffff1a] flex justify-between p-3">
                    <div className="text-[#FFFFFF] text-[0.875rem] font-[800]">
                      All DApps
                    </div>
                    <div className=" flex gap-[1rem]">
                      {/* <div className=" flex text-[0.75rem] text-[#2ED3B7] font-[800]">
              <img src={Filter} alt="Filter" className=" w-5 h-5" />
              <span className=" p-1">This week</span>
            </div>
            <div className=" flex text-[0.75rem] text-[#2ED3B7] font-[800]">
              <span className=" p-1">Transaction Count</span>
              <img src={DownGreen} alt="DownGreen" />
            </div> */}
                    </div>
                  </div>
                  <div className=" h-[4.625rem] bg-[#111213] border-b-2 border-[#ffffff1a] flex justify-between p-4">
                    <div className=" flex flex-col">
                      <div className="text-[1rem] text-[#FFFFFFCC] font-[800]">
                        Total Number of DApps
                      </div>
                    </div>
                    <div className="text-[1rem] text-[#FFFFFFCC] font-[800]">
                      8
                    </div>
                  </div>
                  <div className="pt-3 flex flex-col justify-center align-middle items-center  ">
                    <div
                      className="bg-[#111213] hover:bg-[#252628] cursor-pointer rounded-[1rem] border-2 border-[#ffffff1a] xxl:w-[100%] xl:w-[95%] sxl:w-[95%] bxl:w-[75%] md:w-[95%] sm:w-full xd:w-full  flex justify-between p-4 m-2"
                      onClick={() => handleOnchainDetails(chingari)}
                    >
                      <div className=" flex flex-col">
                        <div className=" truncate text-[0.875rem] text-[#FFFFFF]">
                          CHINGARI
                        </div>
                      </div>
                      <div className="text-[#FFFFFFCC] font-[800]">
                        {isLoading && (
                          <Lottie
                            className="!bg-transparent"
                            loop
                            animationData={Kanaloader}
                            play
                            style={{ width: 50, height: 50 }}
                          />
                        )}
                        {chingariOnchain && chingariOnchain.toLocaleString()}
                      </div>
                    </div>
                    <div
                      className="bg-[#111213] hover:bg-[#252628] cursor-pointer rounded-[1rem] border-2 border-[#ffffff1a] xxl:w-[100%] xl:w-[95%] sxl:w-[95%] bxl:w-[75%] md:w-[95%] sm:w-full xd:w-full  flex justify-between p-4 m-2"
                      onClick={() => handleOnchainDetails(Kanaspot)}
                    >
                      <div className=" flex flex-col">
                        <div className=" truncate text-[0.875rem] text-[#FFFFFF]">
                          KANASPOT
                        </div>
                      </div>
                      <div className="text-[#FFFFFFCC] font-[800]">
                        {isLoading && (
                          <Lottie
                            className="!bg-transparent"
                            loop
                            animationData={Kanaloader}
                            play
                            style={{ width: 50, height: 50 }}
                          />
                        )}
                        {kanaSpotOnchain && kanaSpotOnchain.toLocaleString()}
                      </div>
                    </div>
                    <div
                      className="bg-[#111213] hover:bg-[#252628] cursor-pointer rounded-[1rem] border-2 border-[#ffffff1a] xxl:w-[100%] xl:w-[95%] sxl:w-[95%] bxl:w-[75%] md:w-[95%] sm:w-full xd:w-full  flex justify-between p-4 m-2"
                      onClick={() => handleOnchainDetails(Wapal)}
                    >
                      <div className=" flex flex-col">
                        <div className=" truncate text-[0.875rem] text-[#FFFFFF]">
                          WAPAL
                        </div>
                      </div>
                      <div className="text-[#FFFFFFCC] font-[800]">
                        {isLoading && (
                          <Lottie
                            className="!bg-transparent"
                            loop
                            animationData={Kanaloader}
                            play
                            style={{ width: 50, height: 50 }}
                          />
                        )}
                        {wapalOnchain && wapalOnchain.toLocaleString()}
                      </div>
                    </div>
                    <div
                      className="bg-[#111213] hover:bg-[#252628] cursor-pointer rounded-[1rem] border-2 border-[#ffffff1a] xxl:w-[100%] xl:w-[95%] sxl:w-[95%] bxl:w-[75%] md:w-[95%] sm:w-full xd:w-full  flex justify-between p-4 m-2"
                      onClick={() => handleOnchainDetails(Floc)}
                    >
                      <div className=" flex flex-col">
                        <div className=" truncate text-[0.875rem] text-[#FFFFFF]">
                          FLOC
                        </div>
                      </div>
                      <div className="text-[#FFFFFFCC] font-[800]">
                        {isLoading && (
                          <Lottie
                            className="!bg-transparent"
                            loop
                            animationData={Kanaloader}
                            play
                            style={{ width: 50, height: 50 }}
                          />
                        )}
                        {flocOnchain && flocOnchain.toLocaleString()}
                      </div>
                    </div>
                    <div
                      className="bg-[#111213] hover:bg-[#252628] cursor-pointer rounded-[1rem] border-2 border-[#ffffff1a] xxl:w-[100%] xl:w-[95%] sxl:w-[95%] bxl:w-[75%] md:w-[95%] sm:w-full xd:w-full  flex justify-between p-4 m-2"
                      onClick={() => handleOnchainDetails(Eragon)}
                    >
                      <div className=" flex flex-col">
                        <div className=" truncate text-[0.875rem] text-[#FFFFFF]">
                          ERAGON
                        </div>
                      </div>
                      <div className="text-[#FFFFFFCC] font-[800]">
                        {isLoading && (
                          <Lottie
                            className="!bg-transparent"
                            loop
                            animationData={Kanaloader}
                            play
                            style={{ width: 50, height: 50 }}
                          />
                        )}
                        {eragonOnchain && eragonOnchain.toLocaleString()}
                      </div>
                    </div>
                    <div
                      className="bg-[#111213] hover:bg-[#252628] cursor-pointer rounded-[1rem] border-2 border-[#ffffff1a] xxl:w-[100%] xl:w-[95%] sxl:w-[95%] bxl:w-[75%] md:w-[95%] sm:w-full xd:w-full  flex justify-between p-4 m-2"
                      onClick={() => handleOnchainDetails(Slime)}
                    >
                      <div className=" flex flex-col">
                        <div className=" truncate text-[0.875rem] text-[#FFFFFF]">
                          SLIME
                        </div>
                      </div>
                      <div className="text-[#FFFFFFCC] font-[800]">
                        {isLoading && (
                          <Lottie
                            className="!bg-transparent"
                            loop
                            animationData={Kanaloader}
                            play
                            style={{ width: 50, height: 50 }}
                          />
                        )}
                        {slimeOnChain && slimeOnChain.toLocaleString()}
                      </div>
                    </div>
                    <div
                      className="bg-[#111213] hover:bg-[#252628] cursor-pointer rounded-[1rem] border-2 border-[#ffffff1a] xxl:w-[100%] xl:w-[95%] sxl:w-[95%] bxl:w-[75%] md:w-[95%] sm:w-full xd:w-full  flex justify-between p-4 m-2"
                      onClick={() => handleOnchainDetails(Evolv)}
                    >
                      <div className=" flex flex-col">
                        <div className=" truncate text-[0.875rem] text-[#FFFFFF]">
                          Evolv
                        </div>
                      </div>
                      <div className="text-[#FFFFFFCC] font-[800]">
                        {isLoading && (
                          <Lottie
                            className="!bg-transparent"
                            loop
                            animationData={Kanaloader}
                            play
                            style={{ width: 50, height: 50 }}
                          />
                        )}
                        {evolvOnChain && evolvOnChain.toLocaleString()}
                      </div>
                    </div>
                    <div
                      className="bg-[#111213] hover:bg-[#252628] cursor-pointer rounded-[1rem] border-2 border-[#ffffff1a] xxl:w-[100%] xl:w-[95%] sxl:w-[95%] bxl:w-[75%] md:w-[95%] sm:w-full xd:w-full  flex justify-between p-4 m-2"
                      onClick={() => handleOnchainDetails(SantaBrowser)}
                    >
                      <div className=" flex flex-col">
                        <div className=" truncate text-[0.875rem] text-[#FFFFFF]">
                          SANTA BROWSER
                        </div>
                      </div>
                      <div className="text-[#FFFFFFCC] font-[800]">
                        {isLoading && (
                          <Lottie
                            className="!bg-transparent"
                            loop
                            animationData={Kanaloader}
                            play
                            style={{ width: 50, height: 50 }}
                          />
                        )}
                        {santaBrowserOnchain &&
                          santaBrowserOnchain.toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllDApps;
